<template>
  <q-form @submit="sendEmail()">
    <q-input
      v-model="email"
      filled
      hide-bottom-space
      :label="t('contact.email.label')"
      no-error-icon
      type="email"
      :rules="[
        (val) => (val && val.length > 0) || t('contact.email.validation'),
      ]"
    />
    <q-input
      v-model="tel"
      filled
      :label="t('contact.telephone.label')"
      lazy-rules
      :rules="[
        (val) =>
          !val || isValidPhoneNumber(val) || t('contact.telephone.validation'),
      ]"
      no-error-icon
      type="tel"
    />
    <pricing-slider margin />
    <q-card-actions align="right">
      <div class="flex-grow">
        <svg width="131px" height="48px">
          <use href="#logoLockupSecondary"></use>
        </svg>
      </div>
      <q-btn
        color="accent"
        :label="t('contact.submit.label')"
        no-caps
        text-color="dark"
        type="submit"
        unelevated
      />
      <q-btn flat label="Cancel" no-caps @click="onCloseClick()" />
    </q-card-actions>
  </q-form>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";
import { useQuasar } from "quasar";
import { axios } from "src/boot/axios";
import { i18n } from "src/boot/i18n";
import { isColorDark } from "src/composables/useRandomPalette";
import { useSettingsStore } from "src/stores/settings";
import PricingSlider from "src/components/pricing/PricingSlider.vue";

defineOptions({ name: "ContactFormDialog" });

const emits = defineEmits(["close", "submit"]);

const { t } = i18n.global;
const settingsStore = useSettingsStore();
const { computedPrice, dark, selectedPalette, squareFootage } =
  storeToRefs(settingsStore);
const $q = useQuasar();
const email = ref("");
const tel = ref("");
const textColor = ref("dark");

const handleTextColor = () => {
  textColor.value = isColorDark(selectedPalette.value.accent?.hex || "#000000")
    ? "white"
    : "dark";
};

const isValidPhoneNumber = (val) => {
  const phoneRegex = /^(\(\d{3}\)\s?|\d{3}[-.\s]?)?\d{3}[-.\s]?\d{4}$/;
  return phoneRegex.test(val);
};

const onCloseClick = () => {
  emits("close");
};

const sendEmail = async () => {
  try {
    const response = await axios.post("/api/send-email", {
      to: "chris@quezada.work",
      from: email.value,
      subject: "A homeowner has a question for HipHouse...",
      textBody: `Phone Number: ${tel.value}`,
      htmlBody: `<p>From: ${email.value}</p><p>Telephone: ${tel.value}</p><p>${squareFootage.value} sq.ft. priced at $${computedPrice.value}</p>`,
    });
    $q.notify({ type: "primary", message: "Email sent successfully!" });
  } catch (error) {
    $q.notify({ type: "negative", message: "Failed to send email." });
    console.log(error);
  }
  emits("submit");
};

watch(
  selectedPalette,
  () => {
    handleTextColor();
  },
  { deep: true }
);

onMounted(() => {
  handleTextColor();
});
</script>
