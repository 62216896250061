<template>
  <div />
</template>

<script setup>
import { ref, shallowRef, watch } from "vue";
import { useQuasar } from "quasar";
import { storeToRefs } from "pinia";
import { useDialogStore } from "src/stores/dialog";
import DialogComponent from "./DialogComponent.vue";

const $q = useQuasar();
const dialogStore = useDialogStore();
const { dialogs } = storeToRefs(dialogStore);
const dialogComponent = shallowRef(null);
const dialogCount = ref(0);

defineOptions({ name: "DialogSwitch" });

const show = (data) => {
  switchDialog(data);
  showDialog(data);
};

const showDialog = (data) => {
  $q.dialog({
    component: dialogComponent.value,
    componentProps: {
      data,
    },
  })
    .onOk(() => {})
    .onCancel(() => {})
    .onDismiss(() => {
      dialogStore.hide(data);
    });
};

const switchDialog = (data) => {
  switch (data.type) {
    default:
      dialogComponent.value = DialogComponent;
      break;
  }
};

watch(
  dialogs,
  (dialogs) => {
    if (dialogs.length > dialogCount.value) show(dialogs[dialogs.length - 1]);
    dialogCount.value = dialogs.length;
  },
  { deep: true }
);
</script>
