<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide">
    <q-card class="bg-secondary q-dialog-plugin">
      <q-card-section class="text-center text-h5">
        <div class="q-pa-md">
          {{ t("contact.title")
          }}<span class="relative-position tip tip-text-h5">HipHouse.</span>
        </div>
      </q-card-section>
      <q-card-section>
        <contact-form-dialog @close="onDialogCancel" @submit="onOKClick" />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script setup>
import { useDialogPluginComponent } from "quasar";
import { i18n } from "src/boot/i18n";
import ContactFormDialog from "src/components/contact/ContactFormDialog.vue";

defineOptions({
  name: "DialogComponent",
});
const props = defineProps({});

defineEmits([...useDialogPluginComponent.emits]);

const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } =
  useDialogPluginComponent();

const { t } = i18n.global;

const onOKClick = () => {
  onDialogOK();
};
</script>
