<template>
  <div v-if="$q.screen.gt.sm">
    <q-tabs v-if="menu.length" class="text-dark" indicator-color="accent">
      <q-route-tab
        v-for="item in menu"
        :key="item.title"
        no-caps
        :to="{ name: 'page', params: { slug: item.page.slug.current } }"
      >
        {{ item.title }}
      </q-route-tab>
    </q-tabs>
  </div>
  <q-btn v-else :icon="sharpMenu" size="lg" text-color="dark" unelevated>
    <q-menu>
      <q-card :class="textColorMenu" class="bg-secondary">
        <q-card-section>
          <q-list>
            <q-item
              v-for="item in menu"
              clickable
              :active-class="`${textColorMenu} text-underline`"
              :key="item.title"
              :to="{ name: 'page', params: { slug: item.page.slug.current } }"
            >
              <q-item-label>
                {{ item.title }}
              </q-item-label>
            </q-item>
          </q-list>
        </q-card-section>
      </q-card>
    </q-menu>
  </q-btn>
</template>

<script setup>
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { isColorDark } from "src/composables/useRandomPalette";
import { useSettingsStore } from "src/stores/settings";
import { sharpMenu } from "@quasar/extras/material-icons-sharp";

defineOptions({ name: "HeaderMenu" });

const settingsStore = useSettingsStore();
const { selectedPalette, menu } = storeToRefs(settingsStore);

const textColorMenu = computed(() =>
  isColorDark(selectedPalette.value.secondary?.hex || "#000000")
    ? "text-white"
    : "text-dark"
);
</script>
