<template>
  <footer-menu />
  <div class="text-center q-my-md">
    <svg width="175px" height="64px">
      <use href="#logoLockup"></use>
    </svg>
  </div>
  <div class="q-mb-sm text-small text-center text-dark">
    {{ t("copyright") }}
  </div>
</template>

<script setup>
import { i18n } from "src/boot/i18n";
import FooterMenu from "src/components/footer/FooterMenu.vue";

defineOptions({ name: "FooterComponent" });

const { t } = i18n.global;
</script>
