<template>
  <q-tabs v-if="menu.length" class="text-dark" indicator-color="primary">
    <q-route-tab
      v-for="item in menu"
      :key="item.title"
      no-caps
      :to="{ name: 'page', params: { slug: item.page.slug.current } }"
    >
      {{ item.title }}
    </q-route-tab>
  </q-tabs>
</template>

<script setup>
import { storeToRefs } from "pinia";
import { useSettingsStore } from "src/stores/settings";

defineOptions({ name: "FooterMenu" });

const settingsStore = useSettingsStore();
const { menu } = storeToRefs(settingsStore);
</script>
